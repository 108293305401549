const ID_TOKEN_KEY = "id_token";
const USER = "user";
const IDPM = "idpm";
const IDPMPPB = "idpmppb";
const IDPROFIL = "idprofil";
const IDPPB = "idppb";
const IDPENILAIAN = "idpenilaian";
const IDPTSP = "idptsp";
const TANGGAL = "tanggal";
const CAPCTHA = "_grecaptcha";
const IDPTSPKABKOTA = "idptspkabkota";
const IDSURVEY = "idsurvey";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(USER);
  window.localStorage.removeItem(IDPMPPB);
  window.localStorage.removeItem(IDPM);
  window.localStorage.removeItem(IDPROFIL);
  window.localStorage.removeItem(IDPPB);
  window.localStorage.removeItem(IDPENILAIAN);
  window.localStorage.removeItem(TANGGAL);
  window.localStorage.removeItem(IDPTSP);
  window.localStorage.removeItem(CAPCTHA);
  window.localStorage.removeItem(IDPTSPKABKOTA);
  window.localStorage.removeItem(IDSURVEY);
};

export default { getToken, saveToken, destroyToken };
